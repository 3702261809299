import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addLicense,
  allLicenses,
  blockLicenses,
  clearErrors,
  updateAllLicenses,
  updateOneLicense
} from '../../modules/licenses';
import RenderOnRole from '../components/RenderOnRole';
import { CreateRestaurantDTO, Restaurant } from '@cat-labs.tech/domain';
import ErrorModal from '../components/ErrorModal';
import ConfirmModal from '../components/ConfirmModal';
import AddLicenseModal from '../components/AddLicenseModal';
import Pagination from '../components/Pagination';

const LicenseList = () => {
  const [deletedLicense, setDeletedLicense] = useState<Restaurant | null>(null);
  const [blockedLicense, setBlockedLicense] = useState<Restaurant | null>(null);
  const [showAddNewModal, setShowAddNewModal] = useState<boolean>(false);

  const dispatch = useDispatch();
  // @ts-ignore
  const { licenses } = useSelector((state) => state);


  useEffect(() => {
    dispatch(allLicenses());
  }, []);

  useEffect(() => {
    if (licenses.needUpdateList) {
      dispatch(allLicenses());
    }
  }, [licenses.needUpdateList]);

  const createNewLicense = async (data: CreateRestaurantDTO) => {
    dispatch(addLicense(data));
    setShowAddNewModal(false);
  };

  const blockUnblockLicense = async () => {
    if (blockedLicense) {
      dispatch(blockLicenses(blockedLicense.id));
      setBlockedLicense(null);
    }
  };

  const updateAll = () => {
    dispatch(updateAllLicenses());
  };

  const refreshLicense = (id: string) => {
    dispatch(updateOneLicense(id));
  };

  const errorsClear = () => {
    dispatch(clearErrors());
  };

  const toggleDeleteLicense = (licenses: Restaurant | null) => {
    setDeletedLicense(licenses);
  };

  const toggleBlockedLicense = (licenses: Restaurant | null) => {
    setBlockedLicense(licenses);
  };

  return (
    <div className='row'>
      <div className='col-sm-12'>
        <h4>
          Лицензии
        </h4>
        <nav className='navbar navbar-light'>
          <form className='container-fluid justify-content-start'>
            <RenderOnRole roles={['ADD', 'ADMIN']}>
              <button className='btn btn-sm btn-outline-success' type='button' style={{ marginRight: '10px' }}
                      disabled={licenses.isLoading} onClick={() => setShowAddNewModal(true)}>
                <i className='bi bi-plus' /> Добавить лицензию
              </button>
            </RenderOnRole>
            <RenderOnRole roles={['UPDATE_ALL', 'ADMIN']}>
              <button className='btn btn-sm btn-outline-info' type='button' disabled={licenses.isLoading}
                      onClick={updateAll}>
                <i className='bi bi-arrow-clockwise' /> Продлить все
              </button>
            </RenderOnRole>
          </form>
        </nav>
        <table className='table table-sm'>
          <thead>
          <tr>
            {/*<th scope='col' style={{ width: '10%' }}>ID</th>*/}
            <th scope='col' style={{ width: '20%' }}>Код ресторана</th>
            <th scope='col' style={{ width: '40%' }}>Наименование</th>
            <th scope='col' style={{ width: '10%' }}>Активна до</th>
            <th scope='col' style={{ width: '10%' }}>Блокировка</th>
            <th style={{ width: '20%' }} />
          </tr>
          </thead>
          <tbody>
          {
            licenses.items.map((license: Restaurant) => (
              <tr key={license.id}>
                {/*<td>{license.id}</td>*/}
                <td>
                  <span style={{
                    fontWeight: 'bold',
                    marginRight: '10px',
                  }}>
                    {license.restCode}
                  </span>
                  {license.useCashServerForLicense && `(${license.cashServer})`}
                </td>
                <td>{license.name}</td>
                <td>{new Date(license.endDate).toLocaleDateString()}</td>
                <td>{license.blocked ? <span style={{ color: 'darkred', fontWeight: 'bold' }}>Да</span> : 'Нет'}</td>
                <td>
                  <button className='btn btn-sm btn-outline-success' style={{ marginRight: '10px' }}
                          onClick={() => refreshLicense(license.restCode)} disabled={licenses.isLoading}>
                    <i className='bi bi-arrow-clockwise' />
                  </button>
                  <button className='btn btn-sm btn-outline-info' style={{ marginRight: '10px' }}
                          disabled={licenses.isLoading}>
                    <i className='bi bi-pencil-square' />
                  </button>
                  <button className='btn btn-sm btn-outline-danger' style={{ marginRight: '10px' }}
                          disabled={licenses.isLoading} onClick={() => setBlockedLicense(license)}>
                    <i className='bi bi-slash-circle' />
                  </button>
                  <button className='btn btn-sm btn-outline-danger' style={{ marginRight: '10px' }}
                          disabled={licenses.isLoading} onClick={() => setDeletedLicense(license)}>
                    <i className='bi bi-trash' />
                  </button>
                </td>
              </tr>
            ))
          }
          </tbody>
        </table>
        <Pagination />
        {
          licenses.errors.length !== 0 && <ErrorModal messages={licenses.errors} onClose={errorsClear} />
        }
        {
          deletedLicense !== null &&
          <ConfirmModal title='Удалить лицензию?' submitButtonText='Удалить'
                        message={`Вы собираетесь удалить лицензию "${deletedLicense.name} (${deletedLicense.restCode})"`}
                        onClose={() => toggleDeleteLicense(null)} onSubmit={() => {
          }} />
        }
        {
          blockedLicense !== null &&
          <ConfirmModal title={`${blockedLicense.blocked ? 'Разблокировать' : 'Заблокировать'} лицензию?`}
                        submitButtonText={blockedLicense.blocked ? 'Разблокировать' : 'Заблокировать'}
                        message={`Вы собираетесь ${blockedLicense.blocked ? 'разблокировать' : 'заблокировать'} лицензию "${blockedLicense.name} (${blockedLicense.restCode})"`}
                        onClose={() => toggleBlockedLicense(null)} onSubmit={blockUnblockLicense} />
        }
        {
          showAddNewModal && <AddLicenseModal onClose={() => setShowAddNewModal(false)} onSubmit={createNewLicense} />
        }
      </div>
    </div>
  );
};

export default LicenseList;
