import React from 'react';
import { Link, useLocation } from "react-router-dom";


import UserService from '../../services/UserService';

const Menu = () => {
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");

  return (
    <header className="p-3 bg-dark text-white">
      <div className="container-fluid">
        <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
          <a href="/" className="d-flex align-items-center mb-2 mb-lg-0 text-white text-decoration-none">
            PB Licenses
          </a>

          <ul className="nav col-12 col-lg-auto me-lg-auto mb-2 justify-content-center mb-md-0">
            <li><Link to='/' className={`nav-link px-2 ${splitLocation[1] === "" ? 'text-primary' : 'text-white'}`}>Лицензии</Link></li>
            <li><Link to='/reports' className={`nav-link px-2 ${splitLocation[1] === "reports" ? 'text-primary' : 'text-white'}`}>Отчет</Link></li>
          </ul>

          <form className="col-12 col-lg-auto mb-3 mb-lg-0 me-lg-3">
            <input type="search" className="form-control form-control-dark" placeholder="Поиск..." aria-label="Search" />
          </form>

          <div className="text-end">
            <span className='h6' style={{ marginRight: '15px' }}>{UserService.getUsername()}</span>
            <button type="button" className="btn btn-outline-light me-2" onClick={UserService.doLogout}>Выход</button>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Menu;
