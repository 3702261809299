// @ts-nocheck
import Keycloak from 'keycloak-js'

const url = process.env.NODE_ENV === 'production' ? process.env.NX_KEYCLOAK_URL_PROD : process.env.NX_KEYCLOAK_URL;
const realm = process.env.NODE_ENV === 'production' ? process.env.NX_KEYCLOAK_REALM_PROD : process.env.NX_KEYCLOAK_REALM;
const clientId = process.env.NODE_ENV === 'production' ? process.env.NX_KEYCLOAK_CLIENT_ID_PROD : process.env.NX_KEYCLOAK_CLIENT_ID;

const _kc = new Keycloak({
  url,
  realm,
  clientId,
});

const doLogin = _kc.login;

const doLogout = _kc.logout;

const getToken = () => _kc.token;

const isLoggedIn = () => !!_kc.token;

const updateToken = (successCallback: any) => {
  return _kc.updateToken(5)
    .then(successCallback)
    .catch(doLogin);
}

const getUsername = () => _kc.tokenParsed?.preferred_username;

const hasRole = (roles: any[]) => roles.some((role: any) => _kc.hasRealmRole(role));

const initKeycloak = (onAuthenticatedCallback: () => void) => {
  _kc.init({
    // onLoad: 'check-sso',
    // silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
    pkceMethod: 'S256',
  })
    .then((authenticated: boolean) => {
      if (authenticated) {
        console.log('authenticated', getUsername());
        onAuthenticatedCallback();
      } else {
        console.warn('not authenticated');
        doLogin();
      }
    })
    .catch(console.error);
}

const UserService = {
  initKeycloak,
  doLogin,
  doLogout,
  getToken,
  isLoggedIn,
  updateToken,
  getUsername,
  hasRole
}

export default UserService;
