import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { allLicenses, changePage } from '../../modules/licenses';

const Pagination: FC = () => {
  const dispatch = useDispatch();
  // @ts-ignore
  const { licenses } = useSelector((state) => state);

  const renderPages = () => {
    const pages = [];

    for (let i = 0; i < licenses.pageCount; i++) {
      pages.push({
        id: i,
        name: i + 1,
        onClick: () => {
          dispatch(changePage(i));
          dispatch(allLicenses(i, licenses.limit));
        }
      });
    }

    return pages;
  }

  return <nav>
    {
      licenses.pageCount > 0 && <ul className="pagination pagination-sm justify-content-start">
        <li className={`page-item ${licenses.page === 0 ? 'disabled' : ''}`} style={{ cursor: 'pointer' }}>
          <a className="page-link" onClick={() => {
            dispatch(changePage(licenses.page - 1));
            dispatch(allLicenses(licenses.page - 1, licenses.limit));
          }}>{'<'}</a>
        </li>
        {
          renderPages().map(page => <li className={`page-item ${licenses.page === page.id ? 'active' : ''}`} style={{ cursor: 'pointer' }}><a className="page-link" onClick={page.onClick}>{page.name}</a></li>)
        }
        <li className={`page-item ${licenses.page === licenses.pageCount - 1 ? 'disabled' : ''}`} style={{ cursor: 'pointer' }}>
          <a className="page-link" onClick={() => {
            dispatch(changePage(licenses.page + 1));
            dispatch(allLicenses(licenses.page + 1, licenses.limit));
          }}>{'>'}</a>
        </li>
      </ul>
    }
  </nav>;
};

export default Pagination;
