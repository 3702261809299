import React, { FC, useState } from 'react';
import { CreateRestaurantDTO } from '@cat-labs.tech/domain';

import './ErrorModal.scss';

interface OwnProps {
  onSubmit: (data: CreateRestaurantDTO) => void;
  onClose: () => void;
}

type Props = OwnProps;

const AddLicenseModal: FC<Props> = (props) => {
  const [name, setName] = useState<string>('');
  const [useCashServerForLicense, setUseCashServerForLicense] = useState<boolean>(false);
  const [cashServer, setCashServer] = useState<string>('');
  const [restCode, setRestCode] = useState<string>('');
  const [address, setAddress] = useState<string>('');
  const [price, setPrice] = useState<number | undefined>(undefined);

  const onConfirm = () => {
    props.onSubmit({
      name,
      useCashServerForLicense,
      restCode,
      cashServer,
      address,
      price
    });
  };

  return <div className='frame'>
    <div className='modal modal-license'>
      <span className='title'>Добавление новой лицензии</span>
      <div className='container modal-container'>
        <form>
          <div className='row'>
            <div className='col'>
              <div className='form-floating mb-3'>
                <input id='name' type='text' className='form-control' value={name}
                       onChange={e => setName(e.target.value)} />
                <label htmlFor='name'>Наименование</label>
              </div>
            </div>
            <div className='col'>
              <div className='form-floating mb-3'>
                <input id='restCode' type='number' className='form-control' value={restCode}
                       onChange={e => setRestCode(e.target.value)} />
                <label htmlFor='restCode' className='form-label'>Код ресторана</label>
              </div>
            </div>
          </div>
          <div className='form-check' style={{ marginTop: '10px', marginBottom: '10px' }}>
            <input className='form-check-input' type='checkbox' id='useCashServerForLicense'
                   checked={useCashServerForLicense}
                   onChange={() => setUseCashServerForLicense(!useCashServerForLicense)} />
            <label className='form-check-label' htmlFor='useCashServerForLicense'>
              Использовать разделение по кассовым серверам
            </label>
          </div>
          {
            useCashServerForLicense && <div className='row'>
              <div className='col'>
                <div className='form-floating mb-3'>
                  <input id='cashServer' type='text' className='form-control' value={cashServer}
                         onChange={e => setCashServer(e.target.value)} />
                  <label htmlFor='cashServer' className='form-label'>Наименование кассового сервера</label>
                </div>
              </div>
            </div>
          }
          <div className='row'>
            <div className='col'>
              <div className='form-floating mb-3'>
                <input id='address' type='text' className='form-control' value={address}
                       onChange={e => setAddress(e.target.value)} />
                <label htmlFor='address' className='form-label'>Адрес ресторана</label>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-4'>
              <div className='form-floating mb-3'>
                <input id='price' type='number' className='form-control' value={price}
                       onChange={e => setPrice(Number(e.target.value))} />
                <label htmlFor='price' className='form-label'>Абонентская плата</label>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className='button-group'>
        <div className='button-modal-success' onClick={onConfirm}>Добавить</div>
        <div className='button-modal-success-outline' onClick={props.onClose}>Закрыть</div>
      </div>
    </div>
  </div>;
};

export default AddLicenseModal;
