import React, { FC } from 'react';

import './ErrorModal.scss';

interface OwnProps {
  title: string;
  message: string;
  submitButtonText: string;
  onSubmit: () => void;
  onClose: () => void;
}

type Props = OwnProps;

const ConfirmModal: FC<Props> = (props) => {

  return <div className='frame'>
    <div className='modal'>
      <img src='https://100dayscss.com/codepen/alert.png' width='44' height='38' alt='' />
      <span className='title'>{props.title}</span>
      {
        <p>{props.message}</p>
      }
      <div className='button-group'>
        <div className='button-modal' onClick={props.onSubmit}>{props.submitButtonText}</div>
        <div className='button-modal-outline' onClick={props.onClose}>Закрыть</div>
      </div>
    </div>
  </div>;
};

export default ConfirmModal;
