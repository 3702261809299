import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import StoreService from '../services/StoreService';
import RenderOnAnonymous from './components/RenderOnAnonymous';
import RenderOnAuthenticated from './components/RenderOnAuthenticated';
import LicensesPage from './pages/LicensesPage';

const store = StoreService.setup();

export function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <div className="custom-container">
          <RenderOnAnonymous>
            Welcome
          </RenderOnAnonymous>
          <RenderOnAuthenticated>
            <LicensesPage />
          </RenderOnAuthenticated>
        </div>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
