import React from 'react';

const NoMatch = () => {
  return (
    <div className="container">
      <h1 className="text-danger">404 - Sorry, the page you requested could not be found! 🦄</h1>
    </div>
  );
};

export default NoMatch;
