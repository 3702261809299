import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Menu from '../components/Menu';
import LicenseList from './LicenseList';
import RolesRoute from '../components/RolesRoute';
import NoMatch from '../components/NoMatch';

// @ts-ignore
const LicensesPage = () => {

  return (
    <>
      <Menu />
      <div className='container' style={{ marginTop: '25px' }}>
        <Switch>
          <RolesRoute roles={['LIST', 'ADMIN']} exact path="/">
            <LicenseList />
          </RolesRoute>
          <RolesRoute roles={['REPORTS', 'ADMIN']} exact path="/reports">
            Отчеты
          </RolesRoute>
          <Route path="*">
            <NoMatch/>
          </Route>
        </Switch>
      </div>
    </>
  );
};

export default LicensesPage;
