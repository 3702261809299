import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom';

import UserService from './services/UserService';
import HttpService from './services/HttpService';
import App from './app/app';

import './styles.scss';

const renderApp = () => ReactDOM.render(
  <StrictMode>
    <App/>
  </StrictMode>, document.getElementById("root"));

UserService.initKeycloak(renderApp);
HttpService.configure();
