import React, { FC } from 'react';

import './ErrorModal.scss';

interface OwnProps {
  messages: string[];
  onClose: () => void;
}

type Props = OwnProps;

const ErrorModal: FC<Props> = (props) => {

  return <div className="frame">
    <div className="modal">
      <img src="https://100dayscss.com/codepen/alert.png" width="44" height="38"  alt=''/>
      <span className="title">Ууупс!</span>
      {
        props.messages.map(message => <p>{message}</p>)
      }
      <div className="button" onClick={props.onClose}>Закрыть</div>
    </div>
  </div>;
};

export default ErrorModal;
