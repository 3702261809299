import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from 'redux-devtools-extension';
import axiosMiddleware from "redux-axios-middleware";
import logger from "redux-logger";
import thunk from "redux-thunk";

import rootReducer from "../modules";
import HttpService from "./HttpService";

const setup = () => {
  HttpService.configure();

  const enhancers = [];
  const middleware = [
    thunk,
    axiosMiddleware(HttpService.getAxiosClient())
  ];

  if (process.env.NODE_ENV === 'development') {
    enhancers.push(applyMiddleware(logger));
  }

  const composedEnhancers = composeWithDevTools(applyMiddleware(...middleware), ...enhancers);

  // @ts-ignore
  return createStore(rootReducer, composedEnhancers);
};

const StoreService = {
  setup,
};

export default StoreService;
