// @ts-ignore
import { SUCCESS_SUFFIX, ERROR_SUFFIX } from "redux-axios-middleware";
import HttpService from "../services/HttpService";
import UserService from "../services/UserService";
import { CreateRestaurantDTO, EditRestaurantDTO, Restaurant } from '@cat-labs.tech/domain';

const LIST_LICENSES = 'LIST_LICENSES';
const ADD_LICENSE = 'ADD_LICENSE';
const EDIT_LICENSE = 'EDIT_LICENSE';
const DELETE_LICENSE = 'DELETE_LICENSE';
const UPDATE_LICENSE = 'UPDATE_LICENSE';
const UPDATE_ALL_LICENSE = 'UPDATE_ALL_LICENSE';
const BLOCK_LICENSE = 'BLOCK_LICENSE';
const CLEAR_ERRORS = 'CLEAR_ERRORS';
const CHANGE_PAGE = 'CHANGE_PAGE';
const CHANGE_LIMIT = 'CHANGE_LIMIT';

interface LicensesState {
  items: Restaurant[],
  isLoading: boolean;
  needUpdateList: boolean | undefined;
  page: number;
  limit: number;
  pageCount: number;
  errors: string[];
}

const initialState: LicensesState = {
  items: [],
  isLoading: false,
  needUpdateList: undefined,
  errors: [],
  page: 0,
  limit: 15,
  pageCount: 0
};

const licensesReducer = (state = initialState, action: any): LicensesState => {
  switch (action.type) {
    case LIST_LICENSES:
      return {
        ...state,
        isLoading: true,
        errors: [],
      };

    case LIST_LICENSES + SUCCESS_SUFFIX:
      return {
        ...state,
        items: action.payload.data.items,
        pageCount: action.payload.data.pageCount,
        isLoading: false,
      };

    case LIST_LICENSES + ERROR_SUFFIX:
      return {
        ...state,
        items: [],
        isLoading: false,
        errors: ['Ошибка получения лицензий', action.error.message],
      };

    case ADD_LICENSE:
      return {
        ...state,
        needUpdateList: undefined,
      };

    case ADD_LICENSE + SUCCESS_SUFFIX:
      return {
        ...state,
        needUpdateList: true,
      };

    case ADD_LICENSE + ERROR_SUFFIX:
      return {
        ...state,
        needUpdateList: undefined,
        errors: ['Ошибка добавления лицензии', action.error.message]
      };

    case BLOCK_LICENSE:
      return {
        ...state,
        needUpdateList: undefined,
      };

    case BLOCK_LICENSE + SUCCESS_SUFFIX:
      return {
        ...state,
        needUpdateList: true,
      };

    case BLOCK_LICENSE + ERROR_SUFFIX:
      return {
        ...state,
        needUpdateList: undefined,
        errors: ['Ошибка блокировки лицензии', action.error.message]
      };

    case UPDATE_LICENSE:
      return {
        ...state,
        needUpdateList: undefined,
        isLoading: true,
        errors: [],
      }

    case UPDATE_LICENSE + SUCCESS_SUFFIX:
      return {
        ...state,
        needUpdateList: true,
        isLoading: false,
        errors: [],
      }

    case UPDATE_LICENSE + ERROR_SUFFIX:
      return {
        ...state,
        needUpdateList: undefined,
        isLoading: false,
        errors: [`Ошибка обновления лицензии (ID: ${action.meta.previousAction.payload.licenseId})`, action.error.message],
      }

    case UPDATE_ALL_LICENSE:
      return {
        ...state,
        isLoading: true,
        needUpdateList: undefined,
        errors: [],
      };

    case UPDATE_ALL_LICENSE + SUCCESS_SUFFIX:
      return {
        ...state,
        needUpdateList: true,
        isLoading: false,
      };

    case UPDATE_ALL_LICENSE + ERROR_SUFFIX:
      return {
        ...state,
        isLoading: false,
        needUpdateList: undefined,
        errors: ['Ошибка продления лицензий', action.error.message],
      };

    case DELETE_LICENSE:
      return {
        ...state,
        items: state.items.filter((license: Restaurant) => license.id !== action.payload.license.id),
        isLoading: false,
        errors: [],
      };

    case CHANGE_PAGE:
      return {
        ...state,
        page: action.payload.page,
      };

    case CHANGE_LIMIT:
      return {
        ...state,
        limit: action.payload.limit,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        errors: [],
      }

    default:
      return state;
  }
};

export default licensesReducer;

export const changePage = (page: number) => {
  return {
    type: CHANGE_PAGE,
    payload: {
      page,
    }
  }
}

export const changeLimit = (limit: number) => {
  return {
    type: CHANGE_LIMIT,
    payload: {
      limit,
    }
  }
}

export const allLicenses = (page: number = 0, limit: number = 15) => ({
  type: LIST_LICENSES,
  payload: {
    request: {
      url: `/api/licenses?page=${page}&limit=${limit}`,
      method: HttpService.HttpMethods.GET,
    },
  },
});

export const addLicense = (license: CreateRestaurantDTO) => {
  console.log(`${UserService.getUsername()} added the license "${license.name}"`);
  return {
    type: ADD_LICENSE,
    payload: {
      request: {
        url: '/api/licenses',
        method: HttpService.HttpMethods.POST,
        data: license,
      },
    },
  }
};

export const blockLicenses = (id: number) => ({
  type: BLOCK_LICENSE,
  payload: {
    request: {
      url: `/api/licenses/block?id=${id}`,
      method: HttpService.HttpMethods.GET,
    },
  },
});

export const editLicense = (license: EditRestaurantDTO) => {
  console.log(`${UserService.getUsername()} added the license "${license.name}"`);
  return {
    type: EDIT_LICENSE,
    payload: {
      request: {
        url: '/api/licenses',
        method: HttpService.HttpMethods.PUT,
        data: license,
      },
    },
  }
};

export const deleteLicense = (license: Restaurant) => {
  console.log(`${UserService.getUsername()} deletes the book ${license.name}`);
  return {
    type: DELETE_LICENSE,
    payload: {
      license,
      request: {
        url: `/api/licenses/${license.id}`,
        method: HttpService.HttpMethods.DELETE,
      },
    },
  }
};

export const updateOneLicense = (id: string) => {
  console.log(`${UserService.getUsername()} update license with ID: ${id}`);
  return {
    type: UPDATE_LICENSE,
    payload: {
      licenseId: id,
      request: {
        url: `/api/licenses/refresh/${id}`,
        method: HttpService.HttpMethods.GET,
      }
    }
  }
};

export const updateAllLicenses = () => {
  console.log(`${UserService.getUsername()} update all licenses`);
  return {
    type: UPDATE_ALL_LICENSE,
    payload: {
      request: {
        url: `/api/licenses/refreshAll`,
        method: HttpService.HttpMethods.GET,
      }
    }
  }
};

export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS,
  }
};
